import { Card, CardBody } from '@paljs/ui/Card';
import { InputGroup } from '@paljs/ui/Input';
import { Button } from '@paljs/ui/Button';
import Col from '@paljs/ui/Col';
import Row from '@paljs/ui/Row';
import React, { useEffect, useState, useContext } from 'react';
import styled from 'styled-components';
import SEO from '../../components/SEO';
import axios from 'axios';
import { EvaIcon } from '@paljs/ui/Icon';
import { GlobalStateData } from '../../storage/GlobalDataProvider';
import '../../styles/common.css';
import { CSVLink, CSVDownload } from 'react-csv';
import Select from '@paljs/ui/Select';
import {
  requireCheck,
  resetRequired,
  pullDownReset,
  baseUri,
  showLoader,
  hideLoader,
  numFormatter,
  authCheck,
  dateFormat,
  dateGreaterOrEqual,
} from '../../utils/utils';
import { getFiltered } from '../../utils/table';
import DataTable from 'react-data-table-component';
import { PDFViewer, PDFDownloadLink } from '@react-pdf/renderer';
import PdfGenerator from '../../components/PdfGenerator';

const Input = styled(InputGroup)`
  margin-bottom: 10px;
`;

const ActionBtn = styled(Button)`
  padding: 4px;
  margin-bottom: 10px;
`;

const SelectField = styled(Select)`
  margin-bottom: 10px;
`;

const ItemPage = () => {
  const [itemData, setItemData] = useState();
  const [showDownload, setShowDownload] = useState(false);
  const [tableFilter, setTableFilter] = useState('');
  const [tableData, setTableData] = useState([]);
  const [isClient, setIsClient] = useState(false);
  const [pdfTitles, setPdfTitles] = useState([
    'Customer',
    'Customer Sort',
    'Recipt Number',
    'Lt Num',
    'POC Num',
    'J.Crd Date',
    'Clr',
    'Gry Wdh',
    'Ble Prc',
    'Mts Prc',
  ]);
  const [pdfContent, setPdfContent] = useState([[]]);
  const [pdfData, setPdfData] = useState({});
  const state = useContext(GlobalStateData);
  useEffect(() => {
    authCheck(state.authData);
    getCustomers();
    getSorts('');
    setIsClient(true);
  }, []);

  useEffect(() => {
    if (tableData.length) {
      setShowDownload(true);
    } else {
      setShowDownload(false);
    }
  }, [tableData]);

  useEffect(() => {
    setPdfData({
      fromDate: from,
      toDate: to,
      tableTitles: pdfTitles,
      tableContents: pdfContent,
      documentTitle: 'Transaction',
    });
  }, [pdfContent]);

  const postApi = () => {
    const postData = {
      data: {
        customer,
        customerSort,
        from,
        to,
      },
    };
    let validate = requireCheck(postData);
    if (from && to) {
      var deliveryDateValid = dateGreaterOrEqual(to, from, 'to', 'Invalid To Date');
    } else {
      var deliveryDateValid = true;
    }

    if (!validate || !deliveryDateValid) {
      return false;
    }
    let cust = customer.toString();
    let sort = customerSort.toString();
    showLoader();
    axios
      .get(
        baseUri() +
          'grey-receipt-advice/transaction-report?users=' +
          cust +
          '&customersSort=' +
          sort +
          '&from=' +
          from +
          '&to=' +
          to,
        {
          headers: {
            authorization: 'Bearer ' + state.authData,
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        },
      )
      .then((response) => {
        let totalMetersProcessed = 0;
        let totalBalesProcessed = 0;
        let pdfData: any = [];
        response.data.data.result = response.data.data.result.sort((a: any, b: any) => {
          a.sort_name = a.sort_name ? a.sort_name : '';
          b.sort_name = b.sort_name ? b.sort_name : '';

          return a.name.localeCompare(b.name) || a.sort_name.localeCompare(b.sort_name);
        });
        let prevName = '';
        let prevSortName = '';
        let sortTotCount = 1;
        let sortNameTotal = {
          meters: 0,
          bales: 0,
        };
        let customerTotal = {
          meters: 0,
          bales: 0,
        };

        response.data.data.result.forEach((data: any) => {
          let currName = '  ';
          let currSortName = '  ';
          if (prevSortName === data.sort_name) {
            sortTotCount++;
            sortNameTotal.meters += data?.meters_processed || 0;
            sortNameTotal.bales += data?.bales_processed || 0;
          } else if (prevSortName !== data.sort_name) {
            currSortName = data.sort_name;
            if (sortTotCount > 1) {
              let currPdfData = [
                '  ',
                '  ',
                '  ',
                '  ',
                '  ',
                '  ',
                '  ',
                'Sort Total',
                `${sortNameTotal.bales.toLocaleString('en-IN')}`,
                `${sortNameTotal.meters.toLocaleString('en-IN', {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                })}`,
              ];
              pdfData.push(currPdfData);
            }
            prevSortName = data.sort_name;
            sortTotCount = 1;
            sortNameTotal = {
              meters: data.meters_processed,
              bales: +data.bales_processed,
            };
          }
          if (prevName !== data.name) {
            currName = data.name;
            prevName = currName;
            if (customerTotal.bales !== 0 && customerTotal.meters !== 0 && customerTotal.greyWidth !== 0) {
              pdfData.push([
                '  ',
                '  ',
                '  ',
                '  ',
                '  ',
                '  ',
                '  ',
                'Customer Total',
                `${customerTotal.bales.toLocaleString('en-IN')}`,
                `${customerTotal.meters.toLocaleString('en-IN', {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                })}`,
              ]);
            }
            customerTotal.bales = data.bales_processed;
            customerTotal.meters = data.meters_processed;
          } else {
            customerTotal.bales += data?.bales_processed || 0;
            customerTotal.meters += data?.meters_processed || 0;
          }

          let currRow = [
            currName,
            currSortName,
            data.receipt_number,
            data.lot_num,
            data.poc_num,
            dateFormat(data.job_card_date),
            data.color,
            data.grey_width.toLocaleString('en-IN', { maximumFractionDigits: 2, minimumFractionDigits: 2 }),
            data.bales_processed.toLocaleString('en-IN'),
            data.meters_processed.toLocaleString('en-IN', { maximumFractionDigits: 2, minimumFractionDigits: 2 }),
          ];
          pdfData.push(currRow);
          totalMetersProcessed += +data.meters_processed;
          totalBalesProcessed += +data.bales_processed;
        });
        if (sortTotCount > 1) {
          pdfData.push([
            '  ',
            '  ',
            '  ',
            '  ',
            '  ',
            '  ',
            '  ',
            'Sort Total',
            `${sortNameTotal.bales.toLocaleString('en-IN')}`,
            `${sortNameTotal.meters.toLocaleString('en-IN', {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            })}`,
          ]);
        }
        pdfData.push([
          '  ',
          '  ',
          '  ',
          '  ',
          '  ',
          '  ',
          '  ',
          'Customer Total',
          customerTotal.bales.toLocaleString('en-IN'),
          customerTotal.meters.toLocaleString('en-IN', { maximumFractionDigits: 2, minimumFractionDigits: 2 }),
        ]);
        pdfData.push([
          'Grand Total',
          '  ',
          '  ',
          '  ',
          '  ',
          '  ',
          '  ',
          '  ',
          totalBalesProcessed.toLocaleString('en-IN'),
          totalMetersProcessed.toLocaleString('en-IN', { maximumFractionDigits: 2, minimumFractionDigits: 2 }),
        ]);
        response.data.data.result.push({
          name: 'Total',
          sort_name: '',
          receipt_number: '',
          'DATE(gra.receipt_date)': '',
          meters_processed: totalMetersProcessed.toFixed(2),
          bales_processed: totalBalesProcessed.toFixed(2),
          job_card_date: '',
        });
        setItemData(response.data.data.result);
        setPdfContent(pdfData);
        if (tableFilter) {
          filterChange(tableFilter, response.data.data);
        } else {
          setTableData(response.data.data.result);
        }
        hideLoader();
      })
      .catch((error) => {
        //return  error;
      });
  };

  const [customer, setCustomer] = useState([]);
  const [defaultCustomer, setDefaultCustomer] = useState([{ label: 'All', value: '' }]);
  const [userOptions, setUserOptions] = useState([]);
  const [customerSort, setCustomerSort] = useState([]);
  const [defaultCustomerSort, setDefaultCustomerSort] = useState([{ label: 'All', value: '' }]);
  const [sortOptions, setSortOptions] = useState([
    'Customer',
    'Customer Sort',
    'Recipt Number',
    'Lot Number',
    'Job Card Number',
    'Job Card Date',
    'Bales Processed',
    'Meters Processed',
    'Grey Width',
  ]);
  const [from, setFrom] = useState(dateFormat(new Date().toISOString()));
  const [to, setTo] = useState(dateFormat(new Date().toISOString()));

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showData, setShowData] = useState([]);

  const handleChange = (event) => {
    resetRequired(event);
    switch (event.target.name) {
      case 'from':
        setFrom(event.target.value);
        if (event.target.value) {
          document.getElementsByClassName('to')[0].classList.add('require');
        } else {
          document.getElementsByClassName('to')[0].classList.remove('require');
        }
        break;
      case 'to':
        setTo(event.target.value);
        break;
    }
  };

  const handleCustomerChange = (event) => {
    let selected = [];
    let fliterStr = [];
    if (event.length == 0) {
      event = [{ label: 'All', value: '' }];
    }
    if (event.length > 1 && event[0].label == 'All') {
      event.splice(0, 1);
    }
    if (event.length > 1 && event[event.length - 1].label == 'All') {
      event.splice(0, event.length - 1);
    }
    event.forEach(function (item, index) {
      if (item.value) {
        selected.push(item.value);
        fliterStr.push(`filters[user][id][$in][${index}]=${item.value}`);
      }
    });
    setCustomer(selected);
    setDefaultCustomer(event);
    pullDownReset('customer');
    setCustomerSort([]);
    setDefaultCustomerSort([{ label: 'All', value: '' }]);
    setSortOptions([]);
    getSorts(fliterStr.join('&'));
  };

  const handleCustomerSortChange = (event) => {
    let selected = [];
    if (event.length == 0) {
      event = [{ label: 'All', value: '' }];
    }
    if (event.length > 1 && event[0].label == 'All') {
      event.splice(0, 1);
    }
    if (event.length > 1 && event[event.length - 1].label == 'All') {
      event.splice(0, event.length - 1);
    }
    event.forEach(function (item, index) {
      selected.push(item.value);
    });
    setCustomerSort(selected);
    setDefaultCustomerSort(event);
    pullDownReset('customerSort');
  };

  const clearFormData = () => {
    getSorts('');
    setFrom('');
    setTo('');
    setCustomer([]);
    setDefaultCustomer([{ label: 'All', value: '' }]);
    setCustomerSort([]);
    setDefaultCustomerSort([{ label: 'All', value: '' }]);
    resetRequired();
    document.getElementsByClassName('to')[0].classList.remove('require');
  };

  const getCustomers = () => {
    axios
      .get(baseUri() + 'users?filters[userType][$eq]=4', {
        headers: {
          authorization: 'Bearer ' + state.authData,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        let options = [{ label: 'All', value: '' }];
        response.data.forEach(function (item, index) {
          options.push({ label: item.userCode + ' - ' + item.name, value: item.id });
        });
        setUserOptions(options);
        hideLoader();
      })
      .catch((error) => {
        //return  error;
      });
  };

  const getSorts = (query) => {
    setCustomerSort([]);
    showLoader();
    axios
      .get(baseUri() + `user-sorts?${query}`, {
        headers: {
          authorization: 'Bearer ' + state.authData,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        let options = [{ label: 'All', value: '' }];
        response.data.data.forEach(function (item, index) {
          options.push({ label: item.attributes.sortName, value: item.id });
        });
        setSortOptions(options);
        hideLoader();
      })
      .catch((error) => {
        //return  error;
      });
  };

  const columns = [
    {
      name: 'Customer',
      selector: (row) => `${row.name}`,
      sortable: true,
      maxWidth: '45%',
    },
    {
      name: 'Customer Sort',
      selector: (row) => `${row.sort_name}`,
      sortable: true,
      maxWidth: '45%',
    },
    {
      name: 'Receipt Number',
      selector: (row) => `${row.receipt_number}`,
      sortable: true,
      maxWidth: '45%',
    },
    {
      name: 'Lot Number',
      selector: (row) => `${row.lot_num ? row.lot_num : '-'}`,
      sortable: true,
      maxWidth: '45%',
    },
    {
      name: 'POC Number',
      selector: (row) => `${row.poc_num ? row.poc_num : '-'}`,
      sortable: true,
      maxWidth: '45%',
    },
    {
      name: 'JOB Card Date',
      selector: (row) => `${row.job_card_date ? dateFormat(row.job_card_date) : ''}`,
      sortable: true,
      maxWidth: '45%',
    },
    {
      name: 'Color',
      selector: (row) => `${row.color ? row.color : '-'}`,
      sortable: true,
      maxWidth: '45%',
    },
    {
      name: 'Meters Processed',
      selector: (row) => (row.meters_processed ? +row.meters_processed : 0),
      sortable: true,
      maxWidth: '45%',
    },
    {
      name: 'Bales Processed',
      selector: (row) => (row.bales_processed ? +row.bales_processed : 0),
      sortable: true,
      maxWidth: '45%',
    },
    {
      name: 'Grey Width',
      selector: (row) => (row.grey_width ? +row.grey_width : 0),
      sortable: true,
      maxWidth: '45%',
    },
  ];
  const filterColumns = ['name', 'receipt_number', 'total_meters', 'total_kgs', 'meters_processed'];

  const filterChange = (searchInput, newData = false) => {
    setTableFilter(searchInput);
    let available = [];
    if (newData) {
      available = newData;
    } else {
      available = itemData;
    }
    if (searchInput) {
      let filteredData = getFiltered(searchInput, available, filterColumns, true);
      setTableData(filteredData);
    } else {
      setTableData(available);
    }
  };

  return (
    <>
      <SEO title="GRA Transaction Generator" />
      <Row>
        <Col breakPoint={{ xs: 12, md: 12 }}>
          <Card>
            <header> GRA Transaction Generator</header>
            <CardBody className="cardbody">
              <Row>
                <Col breakPoint={{ xs: 12, md: 4 }}>
                  <label>
                    Customer <span className="red">*</span>
                  </label>
                  <SelectField
                    fullWidth
                    onChange={(e) => handleCustomerChange(e)}
                    options={userOptions}
                    id="customer"
                    name="customer"
                    placeholder="Select Customer *"
                    value={defaultCustomer}
                    isMulti
                    multiple
                    className="pull-down multi-select"
                  />
                </Col>
                <Col breakPoint={{ xs: 12, md: 4 }}>
                  <label>Customer Sort</label>
                  <SelectField
                    fullWidth
                    onChange={(e) => handleCustomerSortChange(e)}
                    options={sortOptions}
                    id="customerSort"
                    name="customerSort"
                    placeholder="Select Customer Sort"
                    value={defaultCustomerSort}
                    isMulti
                    multiple
                    className="pull-down multi-select"
                  />
                </Col>
                <Col breakPoint={{ xs: 12, md: 4 }}>
                  <label>
                    From Date <span className="red">*</span>
                  </label>
                  <Input fullWidth size="Small">
                    <input
                      type="date"
                      name="from"
                      placeholder="From Date"
                      onChange={(e) => handleChange(e)}
                      value={from}
                      className="from require"
                    />
                  </Input>
                </Col>
                <Col breakPoint={{ xs: 12, md: 4 }}>
                  <label>
                    To Date <span className="red">*</span>
                  </label>
                  <Input fullWidth size="Small">
                    <input
                      type="date"
                      name="to"
                      placeholder="To Date"
                      onChange={(e) => handleChange(e)}
                      value={to}
                      className="to require"
                    />
                  </Input>
                </Col>
                <Col breakPoint={{ xs: 12, md: 12 }}>
                  <Button status="Success" type="button" className="btnrad" shape="" halfWidth onClick={postApi}>
                    Get Report
                  </Button>
                  &nbsp; &nbsp;
                  <Button status="danger" type="button" className="btnrad" shape="" halfWidth onClick={clearFormData}>
                    Reset
                  </Button>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
        <Col breakPoint={{ xs: 12, md: 12 }}>
          <Card>
            <header>GRA Transaction</header>
            <CardBody>
              {showDownload && (
                <p>
                  <PdfGenerator documentTitle="Transaction" data={pdfData} />
                  {' '}
                  <CSVLink data={tableData}>Download Excel</CSVLink>
                </p>
              )}
              <Input fullWidth size="Small">
                <input
                  type="text"
                  onChange={(e) => filterChange(e.target.value)}
                  placeholder="Filter"
                  name="tableFilter"
                  value={tableFilter}
                />
              </Input>
              <DataTable
                columns={columns}
                data={tableData}
                fixedHeader
                allowOverflow={false}
                overflowY={false}
                overflowX={false}
                wrap={false}
                highlightOnHover={true}
                pagination={true}
                paginationPerPage={10}
                paginationRowsPerPageOptions={[10, 20, 30, 40, 50]}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default ItemPage;
